import type { FC } from 'react';
import React, { useCallback } from 'react';
import type { FieldErrors, Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import FieldsForm from 'components/commercetools-ui/organisms/fields-form';
import Markdown from 'components/commercetools-ui/organisms/markdown';

export interface Props {
    isOpen: boolean;
    fields: Fields;
    formName: string;
    validateOnBlur?: boolean;
    modalTitle?: string;
    modalDescription?: string;
    showCloseButton?: boolean;
    closeButtonText?: string;
    showSubmitButton?: boolean;
    submitButtonText?: string;
    handleFieldChange: (field: StringFieldDefinition, value: string) => void;
    onClose: () => void;
    onSubmit: (fields: Fields) => Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }>;
    errors?: { isError: true; errorMessage: string; fieldErrors: FieldErrors };
}

const FieldsConfirmationModal: FC<Props> = ({
    isOpen,
    fields,
    formName,
    validateOnBlur,
    modalTitle,
    modalDescription,
    showCloseButton = false,
    closeButtonText = 'Close',
    showSubmitButton = true,
    submitButtonText = 'Submit',
    handleFieldChange,
    onClose,
    onSubmit,
    errors
}) => {
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(
        async (fields: Fields) => {
            const submitResponse = await onSubmit(fields);

            if (!submitResponse.isError) {
                handleClose();
            }

            return submitResponse;
        },
        [onSubmit, handleClose]
    );

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '640px' } }}
            preventScroll={true}
        >
            <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                {modalTitle && <h2 className="text-center text-lg font-bold">{modalTitle}</h2>}
                {modalDescription && <Markdown markdown={modalDescription} />}
                <FieldsForm
                    fields={fields}
                    formName={formName}
                    validateOnBlur={validateOnBlur}
                    handleFieldChange={handleFieldChange}
                    onSubmit={handleSubmit}
                    showSubmitButton={showSubmitButton}
                    submitButtonText={submitButtonText}
                    errors={errors}
                />
                {showCloseButton && closeButtonText && (
                    <Button variant="secondary" size="full" onClick={handleClose} className="rounded-md md:rounded-lg">
                        {closeButtonText}
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default FieldsConfirmationModal;
