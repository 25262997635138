import type { Money } from '@wilm/shared-types/product/Money';

export enum PayNowErrorCodes {
    ORDER_ALREADY_PAID = 'ORDER_ALREADY_PAID',
    ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
    ORDER_NOT_PAID_BY_INVOICE = 'ORDER_NOT_PAID_BY_INVOICE',
    ORDER_CANCELLED = 'ORDER_CANCELLED',
    ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
    ORDER_WAITING_APPROVAL = 'ORDER_WAITING_APPROVAL',
    INVALID_ADDRESS = 'INVALID_ADDRESS',
    UNEXPECTED_ERROR = 'UNEXPECTED_ERROR'
}

export interface PayNowSettings {
    payNowEnabled: boolean;
    enablePartialPayment: boolean;
}

export interface PayNowSuccessResponseType<T> {
    /**
     * The data returned from the API call. The type is set in the generic argument in the {@link LearnerResponse}.
     */
    data: T;
    /**
     * A boolean set to false to indicate the API call was successful.
     */
    isError: false;
}

export interface PayNowErrorResponseType {
    /**
     * A boolean set to true to indicate an error occurred during the API call.
     */
    isError: true;
    /**
     * The error which occurred during the API call.
     */
    errors: [{ code: PayNowErrorCodes; message: string; meta: any }];
}

/**
 * A wrapper for API request returns. On error returns an error, otherwise requested data.
 *
 * @param {T} T - The type of data expected to be returned from a successful API call.
 */
export type PayNowResponse<T> = PayNowSuccessResponseType<T> | PayNowErrorResponseType;

export interface PayNowOrderInfoType {
    orderNumber: string;
    moneyToPay: Money;
    newPaymentSignature: string;
}
