import { fields as billingAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { validate } from '@wilm/shared-types/validation-rules';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import type { Address } from '@wilm/shared-types/account';
import type { BundleLineItemError } from '@wilm/shared-types/sales-link/SalesLinkCart';
import type { Product, Variant } from '@wilm/shared-types/product';

export const hasErrorsInAddress = (address?: Address) => {
    if (!address) {
        return true;
    }
    const billingFieldsWithValues: CustomerAddressFields = Object.keys(billingAddressFields).reduce((acc, key) => {
        const fieldKey = key as keyof CustomerAddressFields;

        acc[fieldKey] = {
            ...billingAddressFields[fieldKey],
            value: address[fieldKey as keyof Address]
        } as (typeof billingAddressFields)[typeof fieldKey];
        return acc;
    }, {} as CustomerAddressFields);

    // loop trough all fields and validate them if error return true
    for (const field of Object.values(billingFieldsWithValues)) {
        const error = validate(field, billingFieldsWithValues);

        if (Object.keys(error).length) {
            return true;
        }
    }
    return false;
};

export const validateSelectedBundleComponent = (
    bundle: Variant,
    selectedBundleComponents: Record<string, string>,
    selectedCommencementDate: string,
    bundleMode: BundleMode,
    selectedCurrency: string
) => {
    const bundleComponents: Product[] | undefined = bundle.attributes?.bundleComponentsProducts;
    const bundleComponentIds = bundleComponents?.map(component => component.productId!) ?? [];

    const error: BundleLineItemError = {
        error: {
            code: '',
            errors: {}
        }
    };

    if (bundleMode === BundleMode.COMMENCEMENT_DATE && !selectedCommencementDate) {
        error.error.code = 'error.bundle.select.one';
        return error;
    }

    const missingSelectedValues = bundleComponentIds.filter(id => !selectedBundleComponents[id]);

    if (bundleMode === BundleMode.SELECT_COMPONENTS && missingSelectedValues.length) {
        error.error.code = 'error.bundle.select.for.each';
        error.error.errors = Object.fromEntries(missingSelectedValues.map(id => [id, 'error.bundle.select.variant']));
        return error;
    }

    // Step 1: Find the selected variant for each bundle component
    const selectedVariants = bundleComponents
        ?.map(component => {
            const selectedSku = selectedBundleComponents[component.productId!];
            return component.variants.find(variant => variant.sku === selectedSku);
        })
        .filter(Boolean);

    // Step 2: Find variants that don't have prices in the selected currency
    const variantsWithMissingPrices = selectedVariants?.filter(variant => {
        const hasPrice = variant?.prices?.[selectedCurrency];
        return !hasPrice;
    });

    // Step 3: Get product IDs for selected components with missing prices
    const productIdsWithMissingPrices = bundleComponents
        ?.filter(component => {
            const selectedSku = selectedBundleComponents[component.productId!];
            return variantsWithMissingPrices?.some(variant => variant?.sku === selectedSku);
        })
        .map(component => component.productId);
    if (productIdsWithMissingPrices?.length) {
        error.error.code = 'error.bundle.some.component.do.not.have.price.for.selected.currency';
        error.error.errors = Object.fromEntries(
            productIdsWithMissingPrices.map(id => [id, 'error.bundle.component.price.for.selected.currency.not.found'])
        );
    }

    return error;
};
