import type { FC } from 'react';
import React from 'react';
import Link from 'next/link';
import Button from 'components/commercetools-ui/atoms/button';
import useCloseFlyouts from 'helpers/hooks/useCloseFlyouts';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useWishlist } from 'frontastic';
import useBundle from 'frontastic/hooks/useBundle';
import type { CartSlideoutProps } from './type';
import CartItem from '../cart/components/cart-item';
import { EmptyState } from '../empty-state';
import OrderPaymentSection from '../order-payment-section';

const CartSlideout: FC<CartSlideoutProps> = ({ emptyStateImage, emptyStateTitle, resetFlag }) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const { data, isEmpty, removeItem, updateItem } = useCart();

    // load wishlist in earlier stage
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: wishlist } = useWishlist();

    const closeFlyouts = useCloseFlyouts();
    const { nonBundleItems, bundleComponents } = useBundle(data?.lineItems ?? []);

    return (
        <>
            {isEmpty ? (
                <EmptyState className="grow" image={emptyStateImage} title={emptyStateTitle} />
            ) : (
                <div className="h-[65vh] grow divide-y divide-neutral-400 overflow-auto px-12 md:px-22">
                    {nonBundleItems?.map(lineItem => (
                        <CartItem
                            key={lineItem.lineItemId}
                            item={lineItem}
                            bundleItems={bundleComponents[lineItem.lineItemId]}
                            hasDelegateErrors={false}
                            removeItemFromCart={removeItem}
                            updateItem={updateItem}
                        />
                    ))}
                </div>
            )}
            <OrderPaymentSection
                isMiniCart={true}
                classNames={{
                    applyDiscountButton: 'px-12 py-24 md:px-22',
                    infoContainer: 'px-12 p-16 md:px-22',
                    totalAmount: 'pb-20',
                    subCost: 'text-14'
                }}
                button={
                    <Link href={'/'}>
                        <Button
                            className="mt-16"
                            variant="primaryCta"
                            size="full"
                            onClick={() => {
                                closeFlyouts();
                                resetFlag();
                            }}
                            data-cy="cart-link"
                        >
                            {formatCartMessage({ id: 'cart.go', defaultMessage: 'Go to cart' })}
                        </Button>
                    </Link>
                }
            />
        </>
    );
};

export default CartSlideout;
