import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useCallback, useState } from 'react';
import type { PaymentObject } from '@wilm/shared-types/cart/Payment';
import { PayNowErrorCodes, type PayNowOrderInfoType, type PayNowResponse, type PayNowSettings } from '@wilm/shared-types/pay-now/PayNow';
import type { Money } from '@wilm/shared-types/product';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { fields as initialAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { initialPayNowConfirmationFields } from '@wilm/shared-types/validation-rules/pay-now';
import type { CardFields } from '@wilm/shared-types/validation-rules/payment';
import { cardFields as initialCardFields } from '@wilm/shared-types/validation-rules/payment';
import type { FieldErrors, Fields } from '@wilm/shared-types/validation-rules/types';
import { sdk } from 'sdk';

interface PayNowOrderProviderProps {
    payNowSettings: PayNowSettings;
    children: React.ReactNode;
}

interface PayNowOrderContextShape {
    payNowSettings: PayNowSettings;
    showThankYouPage: boolean;
    setShowThankYouPage: (showThankYouPage: boolean) => void;
    payNowOrderInfo?: PayNowOrderInfoType;
    payNowOrderInfoLoading: boolean;
    payment?: PaymentObject;
    getPayNowOrderInfo: (
        payNowConfirmationFields: Fields
    ) => Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }>;
    clearPayNowOrderInfo: () => void;
    addPaymentToGetTokenContext: (moneyAmount: Money) => Promise<PayNowResponse<PaymentObject>>;
    payNowConfirmationFields: Fields;
    setPayNowConfirmationFields: React.Dispatch<React.SetStateAction<Fields>>;
    addressFields: CustomerAddressFields;
    setAddressFields: React.Dispatch<React.SetStateAction<CustomerAddressFields>>;
    cardFields: CardFields;
    setCardFields: React.Dispatch<React.SetStateAction<CardFields>>;
}

const PayNowOrderContext = createContext<PayNowOrderContextShape>({} as PayNowOrderContextShape);

const PayNowOrderProvider: FC<PayNowOrderProviderProps> = ({ payNowSettings, children }) => {
    const [payNowOrderInfo, setPayNowOrderInfo] = useState<PayNowOrderInfoType | undefined>(undefined);
    const [payNowOrderInfoLoading, setPayNowOrderInfoLoading] = useState<boolean>(false);
    const [payment, setPayment] = useState<PaymentObject | undefined>(undefined);
    const [showThankYouPage, setShowThankYouPage] = useState<boolean>(false);
    const [payNowConfirmationFields, setPayNowConfirmationFields] = useState<Fields>(initialPayNowConfirmationFields);
    const [cardFields, setCardFields] = useState(initialCardFields);

    const initialAddressFieldsWithoutEmail = useMemo(() => {
        const fields = { ...initialAddressFields };
        fields.email.validation.required = false;
        fields.country.value = 'GB'; // Initial country selection to UK
        return fields;
    }, []);

    const [addressFields, setAddressFields] = useState<CustomerAddressFields>(initialAddressFieldsWithoutEmail);

    const clearPayNowOrderInfo = useCallback(() => {
        setPayNowOrderInfo(undefined);
        setPayNowConfirmationFields(initialPayNowConfirmationFields);
        setCardFields(initialCardFields);
        setAddressFields(initialAddressFieldsWithoutEmail);
    }, [initialAddressFieldsWithoutEmail, setPayNowOrderInfo, setPayNowConfirmationFields, setCardFields, setAddressFields]);

    const addPaymentToGetTokenContext = useCallback(
        async (moneyAmount: Money) => {
            const paymentResponse = await sdk.callAction<PayNowResponse<PaymentObject>>({
                actionName: `payNow/addPaymentToGetTokenContext`,
                payload: {
                    currencyCode: moneyAmount.currencyCode,
                    centAmount: moneyAmount.centAmount,
                    orderNumber: payNowOrderInfo?.orderNumber,
                    customAddress: {
                        firstName: addressFields.firstName.value,
                        lastName: addressFields.lastName.value,
                        streetName: addressFields.streetName.value,
                        additionalStreetInfo: addressFields.additionalStreetInfo.value,
                        city: addressFields.city.value,
                        country: addressFields.country.value,
                        region: addressFields.region.value,
                        postalCode: addressFields.postalCode.value,
                        phone: addressFields.phone.value,
                        email: payNowConfirmationFields.email.value
                    }
                }
            });

            const payment = !paymentResponse.isError && !paymentResponse.data.isError ? paymentResponse.data.data : undefined;
            setPayment(payment);
            if (!paymentResponse.isError) {
                return paymentResponse.data;
            } else {
                const unexpectedErrorResponse: PayNowResponse<PaymentObject> = {
                    isError: true,
                    errors: [
                        {
                            code: PayNowErrorCodes.UNEXPECTED_ERROR,
                            message:
                                'Unexpected error occurred, if the problem persists, contact support with the following error: ' +
                                JSON.stringify(paymentResponse.error.message),
                            meta: {}
                        }
                    ]
                };
                return unexpectedErrorResponse;
            }
        },
        [payNowOrderInfo, addressFields, payNowConfirmationFields.email.value]
    );

    const getPayNowOrderInfo = useCallback(
        async (payNowConfirmationFields: Fields) => {
            setPayNowOrderInfoLoading(true);
            const payload = {
                orderNumber: (payNowConfirmationFields.orderNumber.value as string).trim(),
                email: (payNowConfirmationFields.email.value as string).trim()
            };

            const payNowOrderInfoResults = await sdk.callAction<PayNowResponse<PayNowOrderInfoType>>({
                actionName: 'payNow/getPayNowOrderInfo',
                payload
            });

            setPayNowOrderInfoLoading(false);

            if (payNowOrderInfoResults.isError) {
                clearPayNowOrderInfo();
                return {
                    isError: true,
                    errorMessage:
                        'Error fetching PayNow order info. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(payNowOrderInfoResults.error.message),
                    fieldErrors: {} as FieldErrors
                };
            }

            if (payNowOrderInfoResults.data.isError) {
                clearPayNowOrderInfo();
                return { isError: true, errorMessage: payNowOrderInfoResults.data.errors[0].message, fieldErrors: {} as FieldErrors };
            }

            setPayNowOrderInfo(payNowOrderInfoResults.data.data);
            return { isError: false } as { isError: false };
        },
        [setPayNowOrderInfo, setPayNowOrderInfoLoading, clearPayNowOrderInfo]
    );

    const value = useMemo(
        () => ({
            payNowSettings,
            showThankYouPage,
            setShowThankYouPage,
            payNowOrderInfo,
            payNowOrderInfoLoading,
            payment,
            getPayNowOrderInfo,
            clearPayNowOrderInfo,
            addPaymentToGetTokenContext,
            payNowConfirmationFields,
            setPayNowConfirmationFields,
            addressFields,
            setAddressFields,
            cardFields,
            setCardFields
        }),
        [
            payNowSettings,
            showThankYouPage,
            setShowThankYouPage,
            payNowOrderInfo,
            payNowOrderInfoLoading,
            payment,
            getPayNowOrderInfo,
            clearPayNowOrderInfo,
            addPaymentToGetTokenContext,
            payNowConfirmationFields,
            setPayNowConfirmationFields,
            addressFields,
            setAddressFields,
            cardFields,
            setCardFields
        ]
    );

    return <PayNowOrderContext.Provider value={value}>{children}</PayNowOrderContext.Provider>;
};

export default PayNowOrderProvider;

export const usePayNowOrderContext = () => useContext(PayNowOrderContext);
