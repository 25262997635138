import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { Order } from '@wilm/shared-types/cart/Order';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import type { PaymentLinkInfoType } from '@wilm/shared-types/payment-link/PaymentLink';
import type { ClosePaymentLinkSuccessResponse, SalesLinkResponse } from '@wilm/shared-types/sales-link/Api';
import type { CartCustomerAddress } from '@wilm/shared-types/sales-link/SalesLinkCart';
import { validate } from '@wilm/shared-types/validation-rules';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { cardFields as initialCardFields, paymentFields, validateCardFields } from '@wilm/shared-types/validation-rules/payment';
import type { PaymentFields, CardFields } from '@wilm/shared-types/validation-rules/payment';
import { hasErrorsInAddress } from '@wilm/shared-types/validation-rules/sales-link/cart';
import { type FieldErrors, type StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import type { PaymentData } from 'components/commercetools-ui/organisms/checkout/provider/payment/types';
import ServerError from 'components/commercetools-ui/organisms/server-error';
import MessageModal, { type MessageModalProps } from 'components/sales-link/organisms/message-modal';
import useSessionStorage from 'helpers/hooks/useSessionStorage';
import { sdk } from 'sdk';
import type { SWRResponse } from 'swr';
import useSWR, { mutate } from 'swr';
import { revalidateOptions } from 'frontastic';
import { useCybersourceContext } from '../../cybersource';

export interface PaymentLinkInfoPayload {
    hash: string;
    userAgent: string;
    requestMethod: string;
    requestUrl: string;
}

interface PaymentLinkProviderProps {
    paymentLinkInfoPayload: PaymentLinkInfoPayload;
    children: React.ReactNode;
}

interface PaymentLinkContextShape {
    paymentLinkInfo: PaymentLinkInfoType;
    paymentLinkInfoLoading: boolean;
    cardFields: CardFields;
    isPONumberRequired: boolean;
    poNumberField: PaymentFields['poNumber'];
    handlePoNumberValueChange: (value: string) => void;
    handlePayment: () => Promise<{
        isError: boolean;
        needsToStartFromBeginning: boolean;
        fieldsErrors: FieldErrors;
    }>;
    setCardField: (field: keyof CardFields, value: string) => void;
    setPurchaseOrderNumber: (poNumberField: PaymentFields['poNumber']) => Promise<{
        isError: boolean;
        needsToStartFromBeginning: boolean;
        fieldsErrors: FieldErrors;
    }>;
    setIsTermsAccepted: (isTermsAccepted: boolean) => void;
    setOrderBillingAddress: (addressFields: CustomerAddressFields) => Promise<boolean>;
}

const PaymentLinkContext = createContext<PaymentLinkContextShape>({} as PaymentLinkContextShape);

const PaymentLinkProvider: FC<PaymentLinkProviderProps> = ({ paymentLinkInfoPayload, children }) => {
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [messageData, setMessageData] = useState<MessageModalProps['data']>({ message: '', type: 'error' });
    const [refreshOnClose, setRefreshOnClose] = useState(false);
    const [poNumberField, setPoNumberField] = useSessionStorage('poNumberField', paymentFields.poNumber);

    const router = useRouter();

    const getPaymentLinkInfoResult: SWRResponse<SDKResponse<PaymentLinkInfoType>> = useSWR(
        'action/salesLink/getPaymentLinkInfo',
        () =>
            sdk.callAction({
                actionName: 'salesLink/getPaymentLinkInfo',
                payload: paymentLinkInfoPayload
            }) as unknown as SDKResponse<PaymentLinkInfoType>,
        revalidateOptions
    );

    const paymentLinkInfoLoading = getPaymentLinkInfoResult.isValidating;
    const getPaymentLinkOrderResult: SWRResponse<SDKResponse<Order>> = useSWR(
        'action/salesLink/getPaymentLinkOrder',
        () => {
            if (paymentLinkInfoLoading || !paymentLinkInfo.hash) {
                return {} as SDKResponse<Order>;
            }
            return sdk.callAction({
                actionName: 'salesLink/getPaymentLinkOrder',
                payload: {
                    hash: paymentLinkInfoPayload.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            }) as unknown as SDKResponse<Order>;
        },
        revalidateOptions
    );

    const paymentLinkInfo = useMemo(() => {
        const paymentLinkOpenResult = getPaymentLinkInfoResult.data?.isError
            ? ({} as PaymentLinkInfoType)
            : getPaymentLinkInfoResult.data?.data;
        const paymentLinkOrderResult =
            !getPaymentLinkOrderResult.data?.isError && getPaymentLinkOrderResult.data?.data
                ? { order: getPaymentLinkOrderResult.data.data }
                : {};

        const paymentLinkInfo = {
            ...paymentLinkOpenResult,
            ...paymentLinkOrderResult
        } as PaymentLinkInfoType;
        return paymentLinkInfo;
    }, [getPaymentLinkInfoResult.data, getPaymentLinkOrderResult.data]);

    // setPaymentData will be used when we have more payment methods
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [paymentData, setPaymentData] = useState<PaymentData>({ type: PaymentMethodType.CARD } as PaymentData);

    const isPONumberRequired = useMemo(() => !!paymentLinkInfo.order?.custom?.fields?.isPONumberRequired, [paymentLinkInfo]);

    const orderPoNumber = useMemo(() => paymentLinkInfo.order?.purchaseOrderNumber, [paymentLinkInfo]);

    useEffect(() => {
        if (orderPoNumber && !poNumberField.value) {
            setPoNumberField({
                ...poNumberField,
                value: orderPoNumber
            });
        }
    }, [poNumberField, orderPoNumber]);

    const [cardFields, setCardFields] = useState(initialCardFields);

    const { makeCCPayment } = useCybersourceContext();

    const handlePoNumberValueChange = useCallback(
        (value: string) => {
            setPoNumberField({
                ...poNumberField,
                value
            });
        },
        [poNumberField]
    );

    const openErrorModalWithError = useCallback((error: string, refreshOnClose: boolean) => {
        setMessageData({
            heading: 'ERROR',
            message: error,
            type: 'error',
            showCloseButton: true,
            closeButtonText: 'I understand'
        });
        setIsErrorModalOpen(true);
        setRefreshOnClose(refreshOnClose);
    }, []);

    const setOrderBillingAddress = useCallback(
        async (addressFields: CustomerAddressFields) => {
            const address: CartCustomerAddress = {};
            for (const key in addressFields) {
                const field = addressFields[key];
                address[field.name] = field.value?.toString();
            }

            if (hasErrorsInAddress(address)) {
                return false;
            }
            const addressFieldKeys = [
                'firstName',
                'lastName',
                'phone',
                'streetName',
                'additionalStreetInfo',
                'postalCode',
                'city',
                'region',
                'country'
            ] as const;

            if (
                paymentLinkInfo?.order?.billingAddress &&
                addressFieldKeys.every(field => paymentLinkInfo.order?.billingAddress?.[field] === address[field])
            ) {
                return true;
            }

            const payload = {
                hash: paymentLinkInfo.hash,
                unlockId: paymentLinkInfo.unlockId,
                address
            };

            const result = await sdk.callAction<SalesLinkResponse<Order>>({
                actionName: 'salesLink/setOrderBillingAddress',
                payload
            });

            if (result?.isError) {
                openErrorModalWithError(
                    'Error during saving the address. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error.message),
                    false
                );
                return false;
            }

            if (result.data.isError) {
                openErrorModalWithError(result.data.errors[0].message, false);
                return false;
            }
            await mutate('action/salesLink/getPaymentLinkOrder');
            return true;
        },
        [paymentLinkInfo, openErrorModalWithError]
    );

    const setCardField = useCallback((field: keyof CardFields, value: string) => {
        if (field === 'expiryYear') {
            value = '20' + value;
        }
        setCardFields(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value
            } as StringFieldDefinition
        }));
    }, []);

    const updateOrderStatusAsPaidAndCloseLinkIfHasSuccessTransaction = useCallback(
        async (paymentLinkInfo: PaymentLinkInfoType): Promise<{ order: Order }> => {
            const paymentResponse = await sdk.callAction<{ order: Order }>({
                actionName: 'salesLink/updateOrderStatusAsPaidAndCloseLinkIfHasSuccessTransaction',
                payload: {
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });

            if (paymentResponse.isError || !paymentResponse.data?.order) {
                throw new Error('Error updating order status as paid and closing link');
            }

            return paymentResponse.data;
        },
        []
    );

    const closePaymentLink = useCallback(
        async (paymentLinkInfo: PaymentLinkInfoType): Promise<{ status: ClosePaymentLinkSuccessResponse['linkStatus'] }> => {
            const paymentResponse = await sdk.callAction<{ status: ClosePaymentLinkSuccessResponse['linkStatus'] }>({
                actionName: 'salesLink/closePaymentLink',
                payload: {
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });

            if (paymentResponse.isError || !paymentResponse.data?.status) {
                throw new Error('Error closing payment link');
            }

            return paymentResponse.data;
        },
        []
    );

    const handlePayment = useCallback(async () => {
        console.log('---> poNumberField.value', poNumberField.value);

        // check isTermsAccepted
        if (!isTermsAccepted) {
            openErrorModalWithError('Please accept Terms and Conditions to proceed', false);
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {}
            };
        }

        if (isPONumberRequired) {
            const poNumber = await setPurchaseOrderNumber(poNumberField);
            if (poNumber.isError) {
                return poNumber;
            }
        }

        console.log('---> paymentData.type', paymentData.type);

        if (paymentData.type !== PaymentMethodType.CARD) {
            openErrorModalWithError('Invalid payment method', true);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {}
            };
        }
        console.log('---> payment with card');

        const fieldsErrors = validateCardFields(cardFields);

        console.log('---> fieldsErrors', fieldsErrors);

        if (Object.keys(fieldsErrors).length) {
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors
            };
        }

        const options = {
            expirationMonth: cardFields.expiryMonth.value,
            expirationYear: cardFields.expiryYear.value
        };

        const paymentResult = await makeCCPayment(options, {
            salesLink: {
                hash: paymentLinkInfo.hash,
                unlockId: paymentLinkInfo.unlockId!
            }
        });

        console.log('---> makeCCPayment paymentResult', paymentResult);

        if (!paymentResult) {
            openErrorModalWithError('Payment failed', true);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {}
            };
        }

        if (paymentResult.isError && paymentResult.needsToStartFromBeginning) {
            openErrorModalWithError('Payment failed', true);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {}
            };
        }

        if (paymentResult.isError) {
            const closeLinkResponse = await closePaymentLink(paymentLinkInfo);
            console.info('---> closeLinkResponse', closeLinkResponse);

            openErrorModalWithError(
                'There was an unexpected issue while processing your payment. You will receive a confirmation email if the payment was successfully processed',
                true
            );
        }

        if (!paymentResult.isError) {
            const { order } = await updateOrderStatusAsPaidAndCloseLinkIfHasSuccessTransaction(paymentLinkInfo);

            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem('salesLinkLastPlacedOrder', JSON.stringify(order));
            }
            router.push('/sales-link/thank-you');
        }

        return paymentResult;
    }, [
        isTermsAccepted,
        poNumberField,
        isPONumberRequired,
        paymentLinkInfo,
        paymentData,
        cardFields,
        makeCCPayment,
        openErrorModalWithError,
        updateOrderStatusAsPaidAndCloseLinkIfHasSuccessTransaction
    ]);

    const setPurchaseOrderNumber = useCallback(
        async (poNumberField: PaymentFields['poNumber']) => {
            //validate po number
            const error = validate(poNumberField, paymentFields);

            console.log('---> setPurchaseOrderNumber validate po number error', error);

            if (Object.keys(error).length) {
                return {
                    isError: true,
                    needsToStartFromBeginning: false,
                    fieldsErrors: {
                        [poNumberField.name]: error
                    }
                };
            }

            const response: SDKResponse<any> = await sdk.callAction({
                actionName: 'salesLink/setPurchaseOrderNumber',
                payload: {
                    purchaseOrderNumber: poNumberField.value,
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });

            console.log('---> setPurchaseOrderNumber response', response);

            if (response.isError) {
                openErrorModalWithError(
                    'Error setting purchase order number, please try again. If the problem persists, contact support.',
                    false
                );
                return {
                    isError: true,
                    needsToStartFromBeginning: false,
                    fieldsErrors: {}
                };
            }

            if (response.data?.isError && response.data?.fieldsErrors) {
                return {
                    isError: true,
                    needsToStartFromBeginning: false,
                    fieldsErrors: response.data.fieldsErrors
                };
            }

            return {
                isError: false,
                needsToStartFromBeginning: false,
                fieldsErrors: {}
            };
        },
        [paymentLinkInfo]
    );

    const onErrorModalClose = () => {
        if (refreshOnClose) {
            window.location.reload();
        }
        setIsErrorModalOpen(false);
    };

    const value = useMemo(
        () => ({
            paymentLinkInfo,
            paymentLinkInfoLoading,
            cardFields,
            isPONumberRequired,
            poNumberField,
            handlePoNumberValueChange,
            handlePayment,
            setCardField,
            setPurchaseOrderNumber,
            setIsTermsAccepted,
            setOrderBillingAddress
        }),
        [
            paymentLinkInfo,
            paymentLinkInfoLoading,
            cardFields,
            isPONumberRequired,
            poNumberField,
            handlePoNumberValueChange,
            handlePayment,
            setCardField,
            setPurchaseOrderNumber,
            setIsTermsAccepted,
            setOrderBillingAddress
        ]
    );

    if (getPaymentLinkInfoResult.error) {
        return <ServerError refreshLink="/" />;
    }

    return (
        <PaymentLinkContext.Provider value={value}>
            <MessageModal data={messageData} isOpen={isErrorModalOpen} closeModal={onErrorModalClose} />
            {children}
        </PaymentLinkContext.Provider>
    );
};

export default PaymentLinkProvider;

export const usePaymentLinkContext = () => useContext(PaymentLinkContext);
