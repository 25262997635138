import type { TermsAndConditionsData } from 'components/commercetools-ui/organisms/checkout';
import type { TermsAndConditionsType } from 'components/commercetools-ui/organisms/checkout/components/terms-and-conditions';

/**
 * Sorts variants by start date, if the start date is not available, the variant will be placed at the end of the list
 *
 * @param variants Array of variants to sort
 * @param order Sorting order (asc or desc)
 * @returns Array of sorted variants
 */

export interface TermsAndConditionsObject {
    first?: TermsAndConditionsData[];
    second?: TermsAndConditionsData[];
    third?: TermsAndConditionsData[];
}

export const getTermsAndConditionsMapped = (termsAndConditions: TermsAndConditionsObject, country: string) => {
    const termsAndConditionsMapped: TermsAndConditionsType = {};

    const termsAndConditionsAll = [termsAndConditions?.first ?? [], termsAndConditions?.second ?? [], termsAndConditions?.third ?? []];

    for (const [key, terms] of Object.entries(termsAndConditionsAll)) {
        const termForRightCountry = terms?.find(el => el.country === country || el.country === 'default');
        if (termForRightCountry) {
            termsAndConditionsMapped[`termsAndConditions${key}`] = {
                text: termForRightCountry.markdownText,
                checked: false
            };
        }
    }

    return termsAndConditionsMapped;
};
