import type { FC } from 'react';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import { ProductTypeKey } from '@wilm/shared-types/product/Product';
import useClassNames from 'helpers/hooks/useClassNames';
import useLineItem from 'frontastic/hooks/useLineItem';
import type { BundleLineItemData, MembershipLineItemData, TrainingLineItemData } from 'frontastic/hooks/useLineItem/types';
import AdhocCartItem from '../cart/components/adhoc-line-item';
import BundleCartItem from '../cart/components/bundle-line-item';
import MembershipCartItem from '../cart/components/membership-line-item';
import SummaryItemContainer from '../cart/components/summary-item-container';
import TrainingCartItem from '../cart/components/training-line-item';

type OrderItemProps = {
    lineItem: LineItem;
    isLastItem?: boolean;
    bundleItems?: LineItem[];
    hideAttributes?: boolean;
    showPrice?: boolean;
    containerClassName?: string;
};

const OrderItem: FC<OrderItemProps> = ({ lineItem, isLastItem, bundleItems, hideAttributes, showPrice, containerClassName }) => {
    const productTypeKey = lineItem?.productTypeKey ?? '';
    const counterClassName = useClassNames(['flex w-fit items-center gap-14 border border-input-border hover:border-input-border']);
    containerClassName = useClassNames([!isLastItem ? '' : 'border-none pb-0', containerClassName]);
    const { lineItemData: hookLineItemData } = useLineItem(lineItem);
    let lineItemData;

    if (lineItem.parentBundleProductLineItemId) {
        return <></>;
    }

    switch (productTypeKey) {
        case ProductTypeKey.TRAINING:
            lineItemData = hookLineItemData as TrainingLineItemData;
            return (
                <SummaryItemContainer className={containerClassName}>
                    <TrainingCartItem
                        item={lineItem}
                        learningMode={lineItemData?.learningModeWithLabel}
                        startDate={lineItemData?.startDateWithLabel}
                        location={lineItemData?.locationWithLabel}
                        counterClassName={counterClassName}
                        showQuantity={true}
                        showQuantityEdit={false}
                        hasDelegateErrors={false}
                        hideAttributes={hideAttributes}
                        showPrice={showPrice}
                    />
                </SummaryItemContainer>
            );
        case ProductTypeKey.OTHER:
        case ProductTypeKey.PROFESSIONAL_SERVICE:
            return (
                <SummaryItemContainer className={containerClassName}>
                    <AdhocCartItem
                        counterClassName={counterClassName}
                        item={lineItem}
                        showQuantity={true}
                        showQuantityEdit={false}
                        showPrice={showPrice}
                    />
                </SummaryItemContainer>
            );
        case ProductTypeKey.MEMBERSHIP:
            lineItemData = hookLineItemData as MembershipLineItemData;
            return (
                <SummaryItemContainer className={containerClassName}>
                    <MembershipCartItem
                        item={lineItem}
                        counterClassName={counterClassName}
                        duration={lineItemData?.durationWithLabel}
                        showQuantity={true}
                        showQuantityEdit={false}
                        hasDelegateErrors={false}
                        hideAttributes={hideAttributes}
                        showPrice={showPrice}
                    />
                </SummaryItemContainer>
            );
        case ProductTypeKey.BUNDLE:
            lineItemData = hookLineItemData as BundleLineItemData;

            return (
                <SummaryItemContainer className={containerClassName}>
                    <BundleCartItem
                        item={lineItem}
                        counterClassName={counterClassName}
                        bundleItems={bundleItems}
                        showQuantity={true}
                        showQuantityEdit={false}
                        hasDelegateErrors={false}
                        hideItemsAttributes={hideAttributes}
                        showPrice={showPrice}
                    />
                </SummaryItemContainer>
            );
        default:
            return <></>;
    }
};

export default OrderItem;
