import type { FC, ReactNode } from 'react';
import React from 'react';
import useClassNames from 'helpers/hooks/useClassNames';

interface SummaryItemContainerType {
    children: ReactNode;
    className?: string;
}

const SummaryItemContainer: FC<SummaryItemContainerType> = ({ children, className }) => {
    const classNames = useClassNames(['flex max-w-full items-stretch justify-start border-b py-8 last:border-b-0', className]);
    return (
        <div className={classNames} data-cy="summary-item">
            <div className="max-w-full grow overflow-hidden">{children}</div>
        </div>
    );
};

export default SummaryItemContainer;
