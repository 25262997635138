'use client';
import type { CybersourceSettings } from '@wilm/shared-types/cybersource';
import type { PayNowSettings } from '@wilm/shared-types/pay-now/PayNow';
import { IntlProvider } from 'react-intl';
import i18nConfig from 'i18n.config';
import CybersourceProvider from 'providers/cybersource';
import { sdk } from 'sdk';
import PayNowOrderProvider from './order';

interface PayNowProvidersProps {
    payNowSettings: PayNowSettings;
    cybersourceSettings: CybersourceSettings;
    children: React.ReactNode;
}

export const PayNowProviders = ({ payNowSettings, cybersourceSettings, children }: React.PropsWithChildren<PayNowProvidersProps>) => {
    const locale = i18nConfig.defaultLocale;

    sdk.configureForNext(locale);
    return (
        <IntlProvider locale={locale}>
            <CybersourceProvider cybersourceSettings={cybersourceSettings}>
                <PayNowOrderProvider payNowSettings={payNowSettings}>{children}</PayNowOrderProvider>
            </CybersourceProvider>
        </IntlProvider>
    );
};
