import type { FC } from 'react';
import React from 'react';
import TrashIcon from 'components/icons/trash';

interface ProductNameProps {
    productName: string;
    lineItemId: string;
    removeItem?: (lineItemId: string) => void;
}

const ProductName: FC<ProductNameProps> = ({ productName = '', lineItemId = '', removeItem }) => {
    return (
        <div className="flex items-center justify-between">
            <p className="text-base leading-loose" title={productName}>
                {productName}
            </p>
            {typeof removeItem === 'function' && (
                <button
                    onClick={() => {
                        removeItem(lineItemId ?? '');
                    }}
                    className="block cursor-pointer"
                    data-cy="remove-item"
                >
                    <TrashIcon className="w-20" />
                </button>
            )}
        </div>
    );
};

export default ProductName;
