import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';

interface Props {
    refreshLink: string;
    homeLink?: string;
    errorCode?: string;
}

const ServerError = ({ refreshLink, homeLink, errorCode }: Props) => {
    return (
        <div className="relative h-[80vh] lg:h-[60vh]">
            <div className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-0 pb-60 lg:flex-row-reverse lg:gap-50">
                <div className="mt-5 text-center">
                    <h1 className="text-32">Unexpected error...</h1>
                    <p className="mt-24">We are sorry, but something went wrong. Please try to</p>
                    <a href={refreshLink}>
                        <Button className="mt-28 px-48 py-12 text-16 font-medium">Refresh</Button>
                    </a>
                    {homeLink && (
                        <>
                            <p className="mt-24">or</p>
                            <a href={homeLink}>
                                <Button className="mt-28 px-48 py-12 text-16 font-medium">Go back home</Button>
                            </a>
                        </>
                    )}
                    {errorCode && (
                        <p className="mt-24">
                            If the problem persists, please contact our support and forward the following code: {errorCode}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ServerError;
