'use client';

import React, { useCallback } from 'react';
import type { Money } from '@commercetools/frontend-domain-types/product';
import type { LineItem } from '@commercetools/frontend-domain-types/wishlist';
import { PaymentLinkTypeEnum } from '@wilm/shared-types/sales-link/SalesLink';
import { FieldValidationRules } from '@wilm/shared-types/validation-rules/common';
import debounce from 'lodash.debounce';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Radio from 'components/commercetools-ui/atoms/radio';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import Price from 'components/sales-link/organisms/content/products/price';
import SummaryLineItem from 'components/sales-link/organisms/content/summary/line-item';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { getCartInstalmentOptions } from 'helpers/utils/payment';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
import { useSalesLinkDataContext } from 'providers/sales-link/data';
import useBundle from 'frontastic/hooks/useBundle';

export interface Props {
    termsAndConditions: string;
}
const CartSummary: React.FC<Props> = ({ termsAndConditions }) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const [paymentData, setPaymentData] = React.useState<{ paymentMethod: PaymentLinkTypeEnum; instalmentOptions?: number }>();
    const {
        data,
        transaction,
        purchaseCart,
        calculateTaxes,
        isCartLoading,
        taxCalculated,
        termsAccepted,
        setTermsAccepted,
        changeLineItemQuantity,
        setIsPONumberRequired,
        customerInfo
    } = useSalesLinkCartContext();

    const { nonBundleItems, bundleComponents } = useBundle(data?.lineItems ?? [], false);

    const { bundleMode } = useBrandSettingsContext();

    const { salesLinkSettings } = useSalesLinkDataContext();

    const processing = false;
    const invoiceEnabled = salesLinkSettings.invoiceEnabled;
    const ccEnabled = salesLinkSettings.cybersourceCardEnabled;
    const instalmentsEnabled = salesLinkSettings.instalmentsEnabled;

    const qtyMin = FieldValidationRules.LINE_ITEM_QUANTITY.MIN;
    const qtyMax = FieldValidationRules.LINE_ITEM_QUANTITY.MAX;

    const instalmentOptions = instalmentsEnabled
        ? getCartInstalmentOptions(data, { accountStatus: customerInfo?.status, isB2B: customerInfo?.isB2B ?? false })
        : [];

    const updateCartLineItemQty = useCallback(async (lineItemId: string, count: number, price: Money, bundleComponents: LineItem[]) => {
        // if quantityValue is not a number or quantityValue is not between qtyMin and qtyMax return
        if (isNaN(count) || count < qtyMin || count > qtyMax) {
            return;
        }
        await changeLineItemQuantity(lineItemId, count, price, bundleComponents);
    }, []);

    const debouncedQty = debounce(
        (lineItemId: string, count: number, price: Money, bundleComponents: LineItem[]) =>
            updateCartLineItemQty(lineItemId, count, price, bundleComponents),
        300
    );

    const counterClassName = useClassNames([
        'flex w-fit items-center border border-input-border hover:border-input-border',
        processing ? 'cursor-not-allowed bg-neutral-300' : 'cursor-pointer bg-white'
    ]);

    const handlePaymentMethodChange = (paymentMethod: PaymentLinkTypeEnum, instalmentOption?: number) => {
        setPaymentData({
            paymentMethod,
            instalmentOptions: paymentMethod === PaymentLinkTypeEnum.INSTALMENT ? instalmentOption : undefined
        });
    };

    return (
        <div className=" sticky top-16 col-span-1">
            <div className="bg-white p-16 md:pl-24">
                <h2 className=" border-checkout-border border-b pb-10 text-20">
                    {formatCartMessage({ id: 'cart.summary', defaultMessage: 'Cart summary' })}
                </h2>

                {nonBundleItems?.map(lineItem => (
                    <SummaryLineItem
                        key={lineItem.variant?.sku}
                        lineItem={lineItem}
                        bundleMode={bundleMode}
                        bundleComponents={bundleComponents[lineItem.lineItemId]}
                        counterClassName={counterClassName}
                        quantityChange={debouncedQty}
                    />
                ))}
                {nonBundleItems?.length === 0 && (
                    <p className="my-20 font-bold">{formatCartMessage({ id: 'cart.empty.ask', defaultMessage: 'Your cart is empty.' })}</p>
                )}
                <div className="mb-6 mt-30 flex justify-between">
                    <span>{formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}:</span> <Price price={transaction.subtotal} />
                </div>
                {/* // Discount */}
                {!!transaction.discount.centAmount && (
                    <div className="mb-6 flex justify-between">
                        <span>{formatCartMessage({ id: 'discount', defaultMessage: 'Discount' })}:</span>
                        <span>
                            - <Price price={transaction.discount} />
                        </span>
                    </div>
                )}
                <div className="flex justify-between">
                    <span>{formatCartMessage({ id: 'tax', defaultMessage: 'Tax' })}:</span>{' '}
                    {taxCalculated ? <Price price={transaction.tax} /> : <span>-</span>}
                </div>
                <div className="mt-20 flex justify-between">
                    <span className="font-bold">{formatCartMessage({ id: 'total', defaultMessage: 'Total' })}:</span>
                    <span className="font-bold">{taxCalculated ? <Price price={transaction.total} /> : <span>-</span>}</span>
                </div>
                {ccEnabled && (
                    <Checkbox
                        containerClassName="mt-20"
                        label="Add PO number on payment page"
                        name="addPoNumber"
                        id="addPoNumber"
                        checked={false}
                        onChange={({ checked }) => {
                            setIsPONumberRequired(checked);
                        }}
                    />
                )}

                {/* purchase button */}
                <div className="mt-20">
                    {taxCalculated ? (
                        <>
                            <p className="mb-20 mt-10 font-bold">Payment method:</p>
                            {ccEnabled && (
                                <Radio
                                    name="creditCard"
                                    id="creditCard"
                                    value={PaymentLinkTypeEnum.CREDIT_CARD}
                                    checked={paymentData?.paymentMethod === PaymentLinkTypeEnum.CREDIT_CARD}
                                    onChange={() => {
                                        handlePaymentMethodChange(PaymentLinkTypeEnum.CREDIT_CARD);
                                    }}
                                    disabled={!ccEnabled || isCartLoading}
                                    label="Credit/debit card - full payment (Payment link)"
                                />
                            )}
                            {instalmentOptions.map(instalmentOption => (
                                <Radio
                                    key={'instalmentOption_' + instalmentOption.value}
                                    name={'instalmentOption_' + instalmentOption.value}
                                    id={'instalmentOption_' + instalmentOption.value}
                                    value={PaymentLinkTypeEnum.INSTALMENT}
                                    checked={
                                        paymentData?.paymentMethod === PaymentLinkTypeEnum.INSTALMENT &&
                                        paymentData?.instalmentOptions === instalmentOption.value
                                    }
                                    onChange={() => {
                                        handlePaymentMethodChange(PaymentLinkTypeEnum.INSTALMENT, instalmentOption.value);
                                    }}
                                    disabled={!invoiceEnabled || isCartLoading}
                                    label={`Credit/debit card - ${instalmentOption.value} instalments / ${CurrencyHelpers.formatForCurrency(instalmentOption.highestPaymentAmount)} per instalment (Payment link) `}
                                    className="mt-12"
                                />
                            ))}

                            <Radio
                                name="invoice"
                                id="invoice"
                                value={PaymentLinkTypeEnum.INVOICE}
                                checked={paymentData?.paymentMethod === PaymentLinkTypeEnum.INVOICE}
                                onChange={() => {
                                    handlePaymentMethodChange(PaymentLinkTypeEnum.INVOICE);
                                }}
                                disabled={!invoiceEnabled || isCartLoading}
                                label="Place invoice order"
                                className="mt-12"
                            />

                            <Button
                                onClick={() => {
                                    void purchaseCart(paymentData?.paymentMethod, { instalmentOption: paymentData?.instalmentOptions });
                                }}
                                disabled={isCartLoading}
                                className="mt-12"
                                size="full"
                                loading={isCartLoading}
                            >
                                Finish order
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={() => {
                                void calculateTaxes();
                            }}
                            variant="secondary"
                            size="full"
                            className="mb-12"
                            disabled={isCartLoading}
                            loading={isCartLoading}
                        >
                            {formatCartMessage({ id: 'calculate.tax', defaultMessage: 'Calculate tax' })}
                        </Button>
                    )}
                </div>

                <Checkbox
                    containerClassName="mt-20"
                    label={<Markdown className="text-md" markdown={termsAndConditions} />}
                    name="terms"
                    id="terms"
                    checked={termsAccepted}
                    onChange={({ checked }) => {
                        setTermsAccepted(checked);
                    }}
                />
            </div>
        </div>
    );
};

export default CartSummary;
