'use client';
import React, { useState } from 'react';
import type { CartAddressType } from '@wilm/shared-types/cart/AddressTypes';
import { validate } from '@wilm/shared-types/validation-rules';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { EnumFieldDefinition, Field, FieldErrors, Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import PhoneInput from 'react-phone-number-input';
import type { Value as PhoneNumberValue, Country as CountryCode } from 'react-phone-number-input';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Input from 'components/commercetools-ui/atoms/input';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCountries } from 'providers/countries';

interface Props {
    fields: CustomerAddressFields;
    addressType: CartAddressType;
    handleFieldChange: (field: Field, value: boolean | string, addressType: CartAddressType) => void;
    showSave?: boolean;
    saveButtonText?: string;
    saveButtonClassName?: string;
    onSave?: (fields: CustomerAddressFields, addressType: CartAddressType) => Promise<boolean> | boolean;
    showCancel: boolean;
    onCancel?: () => void;
    isLoading?: boolean;
    children?: React.ReactNode;
}

const AddressForm: React.FC<Props> = ({
    fields,
    addressType,
    handleFieldChange,
    showSave = true,
    saveButtonText = 'Save',
    saveButtonClassName,
    onSave,
    showCancel,
    onCancel,
    isLoading,
    children
}) => {
    const { countries, countryCodes } = useCountries();

    const phoneCountries = countryCodes as CountryCode[];
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });
    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });

    const [formErrors, setFormErrors] = useState<FieldErrors>({});

    const handleFieldBlur = (field: Field, fields: Fields) => {
        const error = validate(field, fields);
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [field.name]: error
        }));
    };

    const checkFields = (fields: CustomerAddressFields) => {
        const errors: FieldErrors = {};
        let hasErrors = false;

        for (const field of Object.values(fields)) {
            const error = validate(field, fields);
            if (error) {
                errors[field.name] = error;
                if (Object.keys(error).length) {
                    hasErrors = true;
                }
            }
        }

        return { errors, hasErrors };
    };

    const handleOnSave = (fields: CustomerAddressFields, addressType: CartAddressType) => {
        const { errors, hasErrors } = checkFields(fields);

        if (hasErrors) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});

        void onSave?.(fields, addressType);
    };

    return (
        <form noValidate={true} onSubmit={e => e.preventDefault()} className="md:col-span-2">
            {/* form with billing address fields */}
            <div className="grid gap-12">
                {Object.values(fields).map(field => {
                    // If the field is hidden, don't render it
                    if (field?.showOnPredicate?.(fields) === false) {
                        return null;
                    }

                    // Check if the field is required
                    const isRequired = field.validation.required ?? field.validation.requiredPredicate?.(fields) ?? false;

                    const errorMessage = formErrors?.[field.name]?.message;

                    const formattedErrorMessage = errorMessage
                        ? formatFieldErrorsMessage({
                              id: errorMessage,
                              defaultMessage: errorMessage
                          })
                        : '';

                    // Custom render for phone field
                    if (field.name === 'phone') {
                        field = field as StringFieldDefinition;
                        return (
                            <div key={field.name}>
                                <label htmlFor="phoneMobile" className="text-left text-14 font-label">
                                    {formatFieldLabelsMessage({ id: `address.${field.name}.label`, defaultMessage: field.name })}
                                </label>

                                <PhoneInput
                                    id={field.name}
                                    name={field.name}
                                    value={field.value as PhoneNumberValue}
                                    international={true}
                                    minLength={field.validation.minLength}
                                    maxLength={field.validation.maxLength}
                                    countryCallingCodeEditable={false}
                                    className={formErrors?.[field.name]?.message && 'error'}
                                    defaultCountry="GB"
                                    onChange={value => {
                                        handleFieldChange(field, value?.toString() ?? '', addressType);
                                    }}
                                    onBlur={() => {
                                        handleFieldBlur(field, fields);
                                    }}
                                    countries={phoneCountries}
                                    required={isRequired}
                                />

                                {formErrors?.[field.name]?.message && (
                                    <Typography className="mt-12 text-sm text-input-error" as="p">
                                        {formattedErrorMessage}
                                    </Typography>
                                )}
                            </div>
                        );
                    }

                    if (field.name === 'email') {
                        field = field as StringFieldDefinition;
                        return (
                            <div key={field.name}>
                                <Input
                                    id={field.name}
                                    name={field.name}
                                    type="hidden"
                                    value={field.value ?? ''}
                                    minLength={field.validation.minLength}
                                    maxLength={field.validation.maxLength}
                                    required={isRequired}
                                />
                            </div>
                        );
                    }

                    // Render the field based on its type
                    if (field.type === FieldType.STRING) {
                        return (
                            <div key={field.name}>
                                <Input
                                    id={field.name}
                                    name={field.name}
                                    type="text"
                                    label={formatFieldLabelsMessage({
                                        id: `address.${field.name}.label`,
                                        defaultMessage: field.name
                                    })}
                                    value={field.value}
                                    minLength={field.validation.minLength}
                                    maxLength={field.validation.maxLength}
                                    required={isRequired}
                                    onChange={e => {
                                        handleFieldChange(field, e.target.value, addressType);
                                    }}
                                    onBlur={() => {
                                        handleFieldBlur(field, fields);
                                    }}
                                    errorMessage={formattedErrorMessage}
                                />
                            </div>
                        );
                    }
                    if (field.type === FieldType.ENUM) {
                        let options = field.options.length ? field.options : field.getOptions?.(fields);

                        if (field.name === 'countryCode' || field.name === 'companyCountry' || field.name === 'country') {
                            options = options?.length ? options : countries!;
                        }

                        return (
                            <div key={field.name}>
                                <Dropdown
                                    name={field.name}
                                    items={options}
                                    className="w-full"
                                    onChange={e => {
                                        handleFieldChange(field, e.target.value, addressType);
                                        handleFieldBlur(
                                            { ...field, value: e.target.value, name: field.name } as EnumFieldDefinition,
                                            fields
                                        );
                                    }}
                                    label={formatFieldLabelsMessage({
                                        id: `address.${field.name}.label`,
                                        defaultMessage: field.name
                                    })}
                                    required={isRequired}
                                    value={field.value}
                                    errorMessage={formattedErrorMessage}
                                    disabled={field.disabled}
                                />
                            </div>
                        );
                    }
                })}
                {children}
                <div className="mt-5 flex justify-end">
                    {showCancel && (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setFormErrors({});
                                onCancel && onCancel();
                            }}
                            className="mr-10"
                        >
                            Cancel
                        </Button>
                    )}
                    {showSave && (
                        <Button
                            className={saveButtonClassName ?? ''}
                            onClick={() => handleOnSave(fields, addressType)}
                            disabled={false}
                            loading={isLoading}
                        >
                            {saveButtonText}
                        </Button>
                    )}
                </div>
            </div>
        </form>
    );
};

export default AddressForm;
