import type { FC } from 'react';
import React from 'react';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import Price from 'components/commercetools-ui/atoms/product-types/price';
import ProductName from 'components/commercetools-ui/atoms/product-types/product-name';
import Quantity from 'components/commercetools-ui/atoms/product-types/quantity';
import DelegateSelection from 'components/commercetools-ui/organisms/cart/components/delegate-selection';
import { useBrandSettingsContext } from 'providers/brand-settings';
import BundleItemContainer from './bundle-item-container';
import CartItem from './cart-item';
interface BundleCartItemProps {
    item: LineItem;
    counterClassName: string;
    updateCartItem?: (newQuantity: number) => Promise<void>;
    removeItem?: (lineItemId: string) => void;
    disabledQuantity?: boolean;
    showQuantity?: boolean;
    showQuantityEdit?: boolean;
    showPrice?: boolean;
    bundleItems?: LineItem[];
    inventoryCheckError?: string;
    showDelegateSelection?: boolean;
    hasDelegateErrors?: boolean;
    hideItemsAttributes?: boolean;
}

const BundleCartItem: FC<BundleCartItemProps> = ({
    item = {} as LineItem,
    counterClassName = '',
    updateCartItem = () => {},
    removeItem,
    disabledQuantity = false,
    showQuantity = false,
    showQuantityEdit = true,
    showPrice = true,
    bundleItems,
    inventoryCheckError,
    showDelegateSelection,
    hasDelegateErrors,
    hideItemsAttributes = false
}) => {
    let bundleCentAmount = 0;
    let bundlePrice = item.price ?? 0;

    const { bundleMode } = useBrandSettingsContext();
    bundleItems?.map(bundleItem => {
        if (bundleItem.price?.centAmount) {
            bundleCentAmount += bundleItem.price?.centAmount;
        }
    });

    if (bundleMode === BundleMode.SELECT_COMPONENTS || bundleMode === BundleMode.SELECT_GROUP) {
        bundlePrice = { ...item.price, centAmount: bundleCentAmount };
    }

    return (
        <>
            {showQuantityEdit && (
                <>
                    <ProductName lineItemId={item.lineItemId} removeItem={removeItem} productName={item.name!} />

                    {/* Price */}
                    {showPrice && <Price price={bundlePrice} discountedPrice={item.discountedPrice as number} />}
                    {/* Quantity */}
                    <div className="mb-14">
                        <Quantity
                            count={item.count ?? 1}
                            counterClassName={counterClassName}
                            updateCartItem={updateCartItem}
                            disabledQuantity={disabledQuantity}
                            showQuantityEdit={showQuantityEdit}
                            showQuantity={showQuantity}
                            inventoryCheckError={inventoryCheckError}
                        />
                    </div>
                </>
            )}

            {!showQuantityEdit && (
                <div className={`grid ${showPrice ? 'grid-cols-[4fr_1fr_2fr]' : 'grid-cols-[4fr_1fr]'}`}>
                    <ProductName lineItemId={item.lineItemId} removeItem={removeItem} productName={item.name!} />

                    {/* Quantity */}
                    <div className="mb-14 text-right">
                        <Quantity
                            count={item.count ?? 1}
                            counterClassName={counterClassName}
                            updateCartItem={updateCartItem}
                            disabledQuantity={disabledQuantity}
                            showQuantityEdit={showQuantityEdit}
                            showQuantity={showQuantity}
                            inventoryCheckError={inventoryCheckError}
                        />
                    </div>

                    {/* Price */}
                    <div className="text-right">
                        {showPrice && <Price price={bundlePrice} discountedPrice={item.discountedPrice as number} />}
                    </div>
                </div>
            )}

            {showDelegateSelection && <DelegateSelection itemId={item.lineItemId} qty={item.count} hasDelegateErrors={hasDelegateErrors} />}
            {bundleItems?.map(lineItem => (
                <div key={lineItem.lineItemId}>
                    <CartItem
                        item={lineItem}
                        Container={BundleItemContainer}
                        showQuantity={false}
                        showQuantityEdit={false}
                        showRemove={false}
                        showPrice={false}
                        hasDelegateErrors={false}
                        hideAttributes={hideItemsAttributes}
                    />
                </div>
            ))}
        </>
    );
};

export default BundleCartItem;
