import type { Account } from '@wilm/shared-types/account';
import { AccountStatus } from '@wilm/shared-types/account/Account';
import type { Cart, Order } from '@wilm/shared-types/cart';
import type { PaymentObject } from '@wilm/shared-types/cart/Payment';
import type { Money } from '@wilm/shared-types/product';
import { sdk } from 'sdk';

export interface AddInitialPaymentResultSuccess {
    isError: false;
    payment: PaymentObject;
}
export interface AddInitialPaymentResultError {
    isError: true;
    errorMessage: string;
}

export interface CartInstalmentOption {
    value: number;
    highestPaymentAmount: {
        fractionDigits: number;
        centAmount: number;
        currencyCode: string;
    };
}

export const getInstalmentsInfoForPayment = ({
    payment
}: {
    payment?: PaymentObject;
}):
    | {
          isInstalmentPayment: false;
          currentInstalmentMoney: null;
          instalmentNumber: null;
          totalInstalments: null;
      }
    | {
          isInstalmentPayment: true;
          currentInstalmentMoney: Money;
          instalmentNumber: number;
          totalInstalments: number;
      } => {
    if (!payment)
        return {
            isInstalmentPayment: false,
            currentInstalmentMoney: null,
            instalmentNumber: null,

            totalInstalments: null
        };

    const instalmentNumber = payment.instalmentNumber;
    const totalInstalments = payment.paymentInstalmentOption;

    const isInstalmentPayment = !!(instalmentNumber && totalInstalments && totalInstalments > 1 && totalInstalments < 13);

    if (!isInstalmentPayment)
        return { isInstalmentPayment: false, currentInstalmentMoney: null, instalmentNumber: null, totalInstalments: null };

    return {
        isInstalmentPayment: true,
        currentInstalmentMoney: payment.amountPlanned,
        instalmentNumber,
        totalInstalments
    };
};

export const getCartInstalmentOptions = (
    cart: Cart,
    options?: { isB2B?: boolean; accountStatus?: AccountStatus }
): CartInstalmentOption[] => {
    const allowedAccountStatuses = [AccountStatus.OPEN];
    const allowedStatuses = options?.accountStatus && allowedAccountStatuses.includes(options.accountStatus);

    if (!allowedStatuses) return [];
    if (options?.isB2B) return [];

    const totalAmount = cart.taxedPrice?.centAmount;
    console.info('---> totalAmount', totalAmount);

    if (!totalAmount) return [];

    const lineItems = cart.lineItems?.filter(Boolean);
    console.info('---> lineItems', lineItems);
    if (!lineItems?.length) return [];

    const lineItemsInstalmentOptions: number[][] = [];
    for (const li of lineItems) {
        const variant = li.variant;
        if (!variant) {
            console.error('Variant is missing in line item', li);
            return [];
        }

        const instalmentAvailable = (variant.attributes?.supportedPaymentMethods as { key: string }[])?.some(
            method => method.key.toLowerCase() === 'instalment'
        );
        if (!instalmentAvailable) {
            console.info('Instalment payment method is not available for this line item', variant);
            return [];
        }

        const options = variant.attributes?.instalmentOptions as number[];
        if (!options?.length) {
            console.error('Instalment options are missing in line item', variant);
            return [];
        }

        lineItemsInstalmentOptions.push(options);
    }

    console.info('---> lineItemsInstalmentOptions', lineItemsInstalmentOptions);

    const [firstArray, ...rest] = lineItemsInstalmentOptions;
    const commonInstalmentOptions = firstArray.filter(value => rest.every(array => array.includes(value)));

    if (!commonInstalmentOptions.length) return [];

    const cartInstalmentOptions: CartInstalmentOption[] = [];

    for (const instalmentOption of commonInstalmentOptions) {
        const amountPerInstalment = totalAmount / instalmentOption;
        const roundedAmountPerInstalment = Math.round(amountPerInstalment);
        const lastInstalmentPrice = Math.round(totalAmount - roundedAmountPerInstalment * (instalmentOption - 1));
        console.info('---> roundedAmountPerInstalment', roundedAmountPerInstalment);
        console.info('---> lastInstalmentPrice', lastInstalmentPrice);

        const currencyCode = cart.taxedPrice.currencyCode!;

        const highestPaymentAmount = {
            fractionDigits: 2,
            centAmount: Math.max(roundedAmountPerInstalment, lastInstalmentPrice),
            currencyCode: currencyCode
        };

        cartInstalmentOptions.push({
            value: instalmentOption,
            highestPaymentAmount
        });
    }

    return cartInstalmentOptions.sort((a, b) => a.value - b.value);
};

export const tokenizeUser = async (token: string, addressId: string, currencyCode: string) => {
    console.info('---> tokenizeUser token', token);

    const response = await sdk.callAction({
        actionName: `cart/tokenizeUser`,
        payload: {
            token,
            addressId,
            currencyCode
        }
    });
    const account = (response.isError ? null : response.data) as Account;

    return account;
};

export const addInitialPaymentToGetTokenContext = async (
    centAmount: number,
    currencyCode: string,
    paymentContainer?: 'cart',
    paymentInstalmentOption?: number
) => {
    const response = await sdk.callAction<PaymentObject>({
        actionName: `cart/addPaymentToGetTokenContext`,
        payload: {
            currencyCode,
            centAmount,
            paymentContainer,
            paymentInstalmentOption
        }
    });

    const payment = response.isError ? null : response.data;

    if (payment) {
        if (!payment.signature) {
            return {
                isError: true,
                errorMessage: 'Error signature missing in payment'
            } as AddInitialPaymentResultError;
        }
        return {
            isError: false,
            payment
        } as AddInitialPaymentResultSuccess;
    } else {
        return {
            isError: true,
            errorMessage: 'Error creating payment'
        } as AddInitialPaymentResultError;
    }
};

export const failOrderById = async (orderId: string) => {
    const response = await sdk.callAction<Order>({
        actionName: `cart/failOrderById`,
        payload: {
            orderId
        }
    });

    return response;
};
