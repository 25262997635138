'use client';
import React, { useMemo, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { CartAddressType } from '@wilm/shared-types/cart/AddressTypes';
import type { SalesLinkCustomerType } from '@wilm/shared-types/sales-link/SalesLink';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import AddressForm from 'components/sales-link/organisms/content/customer-info/address-form';
import SimpleAddress from 'components/sales-link/atoms/simple-address';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
export interface Props {
    customer: SalesLinkCustomerType;
}

const CustomerInfo: React.FC<Props> = ({ customer }) => {
    const {
        data,
        billingFields,
        shippingFields,
        isShippingRequired,
        cartHasValidBillingAddress,
        cartHasValidShippingAddress,
        sameAsBillingAddress,
        handleAddressFieldChange,
        handleSameAsBillingAddressChange,
        changeCartCustomerAddressValue,
        resetAddressFields
    } = useSalesLinkCartContext();

    const customerInfo = useMemo(
        () => [
            { label: 'Contact name', value: customer.name, id: 'contactName' },
            { label: 'Contact number', value: customer.contactNumber, id: 'contactNumber' },
            { label: 'Contact ID', value: customer.contactId, id: 'contactId' },
            { label: 'Contact Email', value: customer.email, id: 'contactEmail' },
            { label: 'Account name', value: customer.accountName, id: 'accountName' },
            { label: 'Customer type', value: customer.isB2B ? 'B2B' : 'B2C', id: 'customerType' }
        ],
        [customer]
    );

    const [displayBillingForm, setDisplayBillingForm] = useState(!cartHasValidBillingAddress);
    const [displayShippingForm, setDisplayShippingForm] = useState(!cartHasValidShippingAddress);

    const handleAddressSave = async (fields: CustomerAddressFields, addressType: CartAddressType) => {
        const addressSaved = await changeCartCustomerAddressValue(fields, addressType);
        if (addressSaved) {
            if (addressType === CartAddressType.BILLING) {
                setDisplayBillingForm(false);
            } else {
                setDisplayShippingForm(false);
            }
        }
        if (addressType === CartAddressType.BILLING && sameAsBillingAddress) {
            const shippingAddressSaved = await changeCartCustomerAddressValue(fields, CartAddressType.SHIPPING);
            return shippingAddressSaved && addressSaved;
        }
        return addressSaved;
    };

    return (
        <>
            <h3 className="mb-10 text-20">Contact details</h3>
            <div className="grid grid-cols-2 gap-12 lg:grid-cols-3">
                {customerInfo.map(info => (
                    <div key={info.id} className="border-checkout-border  rounded-md border bg-neutral-200 p-12 pt-6 ">
                        <div>
                            <span className="text-md">{info.label}:</span>
                            <p className="break-words font-bold">{info.value}</p>
                        </div>
                    </div>
                ))}

                <div
                    id="billing-address-wrapper"
                    className="border-checkout-border col-span-2  rounded-md border bg-neutral-200 p-12 pt-6 lg:col-span-3"
                >
                    <div className="flex items-start justify-between">
                        <div>
                            <span className="text-md">Billing Address:</span>
                            {!displayBillingForm && <SimpleAddress address={data.billingAddress} containerClassName="font-bold" />}
                        </div>
                        {!displayBillingForm && (
                            <button onClick={() => setDisplayBillingForm(true)} className="mt-4">
                                <PencilSquareIcon className="w-20" />
                            </button>
                        )}
                    </div>
                    <div
                        className={`mt-16 overflow-hidden rounded-md bg-white transition-[max-height] duration-500 ease-in-out ${displayBillingForm ? 'max-h-[1200px]' : 'max-h-0'}`}
                    >
                        <div className="grid gap-12 px-16 pb-30 pt-16 lg:grid-cols-3">
                            <AddressForm
                                fields={billingFields}
                                addressType={CartAddressType.BILLING}
                                handleFieldChange={handleAddressFieldChange}
                                onSave={handleAddressSave}
                                showCancel={cartHasValidBillingAddress}
                                onCancel={() => {
                                    setDisplayBillingForm(false);
                                    resetAddressFields(CartAddressType.BILLING);
                                }}
                            />
                        </div>
                    </div>
                </div>

                {isShippingRequired && (
                    <div className="border-checkout-border col-span-2  rounded-md border bg-neutral-200 p-12 pt-6 lg:col-span-3">
                        <div className="flex items-start justify-between">
                            <div>
                                <span className="text-md">Shipping Address: </span>
                                <Checkbox
                                    containerClassName="my-16"
                                    label="Same as billing address"
                                    labelPosition="on-right"
                                    checked={sameAsBillingAddress}
                                    onChange={({ checked }) => handleSameAsBillingAddressChange(checked)}
                                    disableBackground
                                    id="sameAsBillingAddress"
                                    name="sameAsBillingAddress"
                                />

                                {!displayShippingForm && !sameAsBillingAddress && cartHasValidShippingAddress && (
                                    <SimpleAddress address={data.shippingAddress} containerClassName="font-bold" />
                                )}
                            </div>

                            {!displayShippingForm && !sameAsBillingAddress && (
                                <button onClick={() => setDisplayShippingForm(!displayShippingForm)} className="mt-4">
                                    <PencilSquareIcon className="w-20" />
                                </button>
                            )}
                        </div>

                        {!sameAsBillingAddress && (
                            <div
                                className={`mt-16 overflow-hidden rounded-md bg-white transition-[max-height] duration-500 ease-in-out ${displayShippingForm ? 'max-h-[1200px]' : 'max-h-0'}`}
                            >
                                <div className="grid gap-12 px-16 pb-30 pt-16 lg:grid-cols-3">
                                    <AddressForm
                                        fields={shippingFields}
                                        addressType={CartAddressType.SHIPPING}
                                        handleFieldChange={handleAddressFieldChange}
                                        onSave={handleAddressSave}
                                        showCancel={cartHasValidShippingAddress}
                                        onCancel={() => {
                                            setDisplayShippingForm(false);
                                            resetAddressFields(CartAddressType.SHIPPING);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomerInfo;
