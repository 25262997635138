import { useEffect, useState, type FC } from 'react';
import type { Money } from '@commercetools/frontend-domain-types/product';
import MoneyInput, { type TMoneyValue } from '@commercetools-uikit/money-input';
import Radio from 'components/commercetools-ui/atoms/radio';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

interface AmountToPayProps {
    moneyToPay: Money;
    onAmountChange: (money: Money) => void;
    enablePartialPayment?: boolean;
}

type TCustomEvent = {
    target: {
        id?: string;
        name?: string;
        value?: string | string[] | null;
    };
    persist?: () => void;
};

const AmountToPay: FC<AmountToPayProps> = ({ moneyToPay, onAmountChange, enablePartialPayment }) => {
    const { formatMessage: formatOrderMessage } = useFormat({ name: 'order' });

    const [moneyValue, setMoneyValue] = useState(MoneyInput.parseMoneyValue({ ...moneyToPay, centAmount: 0 } as TMoneyValue, 'en'));
    const [selectedAmountMode, setSelectedAmountMode] = useState<'full' | 'custom'>('full');

    useEffect(() => {
        if (moneyToPay.centAmount && selectedAmountMode === 'full') {
            onAmountChange(moneyToPay);
        }
    }, [moneyToPay]);
    const handleAmountChange = (event: TCustomEvent) => {
        if (event.target.name?.endsWith('.amount')) {
            // restrict input to two decimal places
            const value = typeof event.target.value === 'object' ? event.target.value?.join('') : event.target.value;

            if (!value) {
                return;
            }

            // regex to allow only numbers and commas and if dot restrict to two symbol decimals
            const regex = /^[0-9,]+(\.\d{0,2})?$/;
            if (!regex.test(value)) {
                return;
            }

            const newMoneyTValue = moneyValue;
            newMoneyTValue.amount = value;

            const newMoneyValue = MoneyInput.convertToMoneyValue(newMoneyTValue, 'en');

            if (!newMoneyValue) {
                return;
            }

            setMoneyValue(newMoneyTValue);

            onAmountChange(newMoneyValue);
            if (selectedAmountMode !== 'custom') {
                setSelectedAmountMode('custom');
            }
        }
    };

    console.info('---> enablePartialPayment', enablePartialPayment);

    return (
        <>
            <h2 className="text-lg">{formatOrderMessage({ id: 'pay.now.amount.to.pay', defaultMessage: 'Amount to pay' })}</h2>

            {!enablePartialPayment && (
                <p className="mb-10">
                    {formatOrderMessage({ id: 'full.amount', defaultMessage: 'Full amount' }) +
                        ' ' +
                        CurrencyHelpers.formatForCurrency(moneyToPay)}
                </p>
            )}

            {enablePartialPayment && (
                <>
                    <p>
                        {formatOrderMessage({
                            id: 'pay.now.amount.to.pay.description',
                            defaultMessage: 'Select the invoice amount that you want to pay via credit/debit card.'
                        })}
                    </p>

                    <Radio
                        className="mt-20"
                        name="amountToPay"
                        value="full"
                        label={
                            formatOrderMessage({ id: 'full.amount', defaultMessage: 'Full amount' }) +
                            ' ' +
                            CurrencyHelpers.formatForCurrency(moneyToPay)
                        }
                        checked={selectedAmountMode === 'full'}
                        onChange={() => {
                            onAmountChange(moneyToPay);
                            setSelectedAmountMode('full');
                        }}
                    />

                    <Radio
                        className="mt-20"
                        name="amountToPay"
                        value="custom"
                        checked={selectedAmountMode === 'custom'}
                        label={formatOrderMessage({ id: 'partial.amount', defaultMessage: 'Partial amount' })}
                        onChange={() => {
                            onAmountChange(MoneyInput.convertToMoneyValue(moneyValue, 'en')!);
                            setSelectedAmountMode('custom');
                        }}
                    >
                        <MoneyInput name="moneyToPay" value={moneyValue} onChange={handleAmountChange} currencies={[]} />
                    </Radio>
                </>
            )}
        </>
    );
};

export default AmountToPay;
