'use client';
import type { FC } from 'react';
import type { CybersourceSettings } from '@wilm/shared-types/cybersource';
import type { PayNowSettings } from '@wilm/shared-types/pay-now/PayNow';
import type { TermsAndConditionsObject } from 'helpers/termsAndConditions';
import { PayNowProviders } from 'providers/pay-now';
import PayNowContent from './content';

interface PayNowProps {
    payNowSettings: PayNowSettings;
    pageTitle?: string;
    pageDescription?: string;
    termsAndConditions: TermsAndConditionsObject;
    cybersourceSettings: CybersourceSettings;
}

const PayNow: FC<PayNowProps> = ({ payNowSettings, cybersourceSettings, pageTitle, pageDescription, termsAndConditions }) => {
    return (
        <PayNowProviders payNowSettings={payNowSettings} cybersourceSettings={cybersourceSettings}>
            <PayNowContent pageTitle={pageTitle} pageDescription={pageDescription} termsAndConditions={termsAndConditions} />
        </PayNowProviders>
    );
};
export default PayNow;
