import { FieldValidationRules } from '@wilm/shared-types/validation-rules/common';
import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { FieldErrors, Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import type { LearnerSeat } from '@wilm/shared-types/learner/Learner';
import { validate } from '@wilm/shared-types/validation-rules';

export interface OpenLearnersFields extends Fields {
    orderNumber: StringFieldDefinition;
}

export const initialOpenLearnersFields: Fields = {
    orderNumber: {
        name: 'orderNumber',
        type: FieldType.STRING,
        placeholder: 'A-AA-1234-5678',
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 100,
            errorMessages: {
                validation: 'error.missing.orderNumber',
                range: 'error.range.orderNumber'
            }
        }
    }
};

export const initialLearnerSeatFields: Fields = {
    firstName: {
        name: 'firstName',
        type: FieldType.STRING,
        value: '',
        validation: {
            requiredPredicate(fields) {
                return !!fields.lastName.value || !!fields.email.value;
            },
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            errorMessages: {
                validation: 'error.missing.firstName',
                range: 'error.range.firstName',
                regex: 'error.validation.firstName'
            }
        }
    },
    lastName: {
        name: 'lastName',
        type: FieldType.STRING,
        value: '',
        validation: {
            requiredPredicate(fields) {
                return !!fields.firstName.value || !!fields.email.value;
            },
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            errorMessages: {
                validation: 'error.missing.lastName',
                range: 'error.range.lastName',
                regex: 'error.validation.lastName'
            }
        }
    },
    email: {
        name: 'email',
        type: FieldType.STRING,
        value: '',
        validation: {
            requiredPredicate(fields) {
                return !!fields.firstName.value || !!fields.lastName.value;
            },
            regex: FieldValidationRules.EMAIL.REGEX,
            minLength: FieldValidationRules.EMAIL.MIN,
            maxLength: FieldValidationRules.EMAIL.MAX,
            errorMessages: {
                validation: 'error.missing.email',
                range: 'error.range.email',
                regex: 'error.validation.email'
            }
        }
    }
};

const findDuplicatedEmails = (learnerSeats: Omit<LearnerSeat, 'provisioning'>[]) => {
    const emailSet = new Set<string>();
    const duplicatedEmails: string[] = [];

    learnerSeats.forEach(learnerSeat => {
        const email = learnerSeat.learner.email;
        if (email) {
            if (emailSet.has(email)) {
                if (!duplicatedEmails.includes(email)) {
                    duplicatedEmails.push(email);
                }
            } else {
                emailSet.add(email);
            }
        }
    });

    return duplicatedEmails;
};

export const getLineItemLearnerSeatsDuplicatedEmailErrors = (
    seatsFields: Record<string, Fields>,
    assignedSeats: LearnerSeat[] | undefined,
    newSeats: Omit<LearnerSeat, 'provisioning'>[]
): Record<string, FieldErrors> => {
    // Find duplicates within assigned seats
    const assignedSeatEmails = new Set<string>((assignedSeats ?? []).map(seat => seat.learner.email).filter(Boolean));

    // Find duplicates within new seats
    const duplicatesInNewSeats = findDuplicatedEmails(newSeats);

    // Track which new emails conflict with already assigned seats
    const conflictsWithAssigned: string[] = [];
    newSeats.forEach(seat => {
        const email = seat.learner.email;
        if (email && assignedSeatEmails.has(email)) {
            conflictsWithAssigned.push(email);
        }
    });

    const validationErrors: Record<string, FieldErrors> = {};

    // Process each seat field
    for (const [seatFieldIndex, seatFields] of Object.entries(seatsFields)) {
        for (const [seatFieldName, seatField] of Object.entries(seatFields)) {
            let fieldError = validate(seatField, seatFields);

            // Check for email errors
            if (seatField.name === 'email' && seatField.value) {
                const email = seatField.value as string;

                if (conflictsWithAssigned.includes(email)) {
                    // Email conflicts with already assigned seats
                    fieldError = {
                        message: 'error.learner.assigned.email'
                    };
                } else if (duplicatesInNewSeats.includes(email)) {
                    // Email is duplicated within new seats
                    fieldError = {
                        message: 'error.learner.duplicated.email'
                    };
                }
            }

            // Add errors if found
            if (Object.keys(fieldError).length > 0) {
                validationErrors[seatFieldIndex] = validationErrors[seatFieldIndex] ?? {};
                validationErrors[seatFieldIndex][seatFieldName] = fieldError;
            }
        }
    }

    return validationErrors;
};
