'use client';
import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import OrderNumber from 'components/commercetools-ui/organisms/pay-now/order-number';
import PaymentFields from 'components/commercetools-ui/organisms/pay-now/payment-fields';
import Redirect from 'helpers/redirect';
import type { TermsAndConditionsObject } from 'helpers/termsAndConditions';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { usePayNowOrderContext } from 'providers/pay-now/order';

interface PayNowContentProps {
    pageTitle?: string;
    pageDescription?: string;
    termsAndConditions: TermsAndConditionsObject;
}

const PayNowContent: FC<PayNowContentProps> = ({ pageTitle, pageDescription, termsAndConditions }) => {
    const { payNowSettings, showThankYouPage, setShowThankYouPage } = usePayNowOrderContext();
    const brandSettings = useBrandSettingsContext();
    if (!payNowSettings?.payNowEnabled) {
        return <Redirect target={brandSettings.umbracoFrontendHost + brandSettings.umbracoNotFoundLink} />;
    }
    return (
        <div className="p-15 md:p-40">
            {/* Page title and description */}
            {pageTitle && <h1 className="mb-8 text-2xl">{pageTitle}</h1>}

            {!showThankYouPage && (
                <>
                    {pageDescription && <Markdown markdown={pageDescription} />}

                    {/* Order number */}
                    <div className="mt-30 max-w-400">
                        <OrderNumber />
                    </div>

                    {/* Payment fields */}
                    <div className="mt-30">
                        <PaymentFields termsAndConditions={termsAndConditions} />
                    </div>
                </>
            )}

            {showThankYouPage && (
                <div className="mt-30">
                    <p className="">The payment has been successfully processed and added to your order in My Account.</p>

                    <Button className="mt-30 block" variant="primary">
                        <Link link="/account">Go to My Account</Link>
                    </Button>

                    <Button className="mt-20" variant="primary" onClick={() => setShowThankYouPage(false)}>
                        Pay another order
                    </Button>
                </div>
            )}
        </div>
    );
};
export default PayNowContent;
