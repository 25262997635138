import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useState, useCallback } from 'react';
import type {
    AssignLearnersPayload,
    AssignLearnersResponse as AssignLearnersBFFResponse,
    LearnerResponse
} from '@wilm/shared-types/learner/Api';
import type { LearnerOrderInfoType, LearnerSettings } from '@wilm/shared-types/learner/Learner';
import { LearnerHashStatusEnum } from '@wilm/shared-types/learner/Learner';
import { initialOpenLearnersFields } from '@wilm/shared-types/validation-rules/learner';
import type { FieldErrors, Fields } from '@wilm/shared-types/validation-rules/types';
import Modal from 'components/commercetools-ui/atoms/modal';
import ProgressBar from 'components/commercetools-ui/atoms/progress-bar';
import AssignLearnersModal from 'components/learner/organisms/assign-learners-modal';
import FieldsConfirmationModal from 'components/learner/organisms/fields-confirmation-modal';
import { sdk } from 'sdk';

export interface LearnerHashInfo {
    hash: string;
    status: LearnerHashStatusEnum;
    userAgent: string;
    requestUrl: string;
    requestMethod: string;
    learnerOrderInfo?: LearnerResponse<LearnerOrderInfoType>;
}
interface LearnerDataProviderProps {
    learnerHashInfo: LearnerHashInfo;
    learnerSettings: LearnerSettings;
    children: React.ReactNode;
}

interface AssignLearnersResponse {
    isError: boolean;
    errorMessage: string;
    fieldErrors: Record<string, FieldErrors>;
}

interface BatchAssignLearnersResponse extends AssignLearnersResponse {
    erroredSeats: AssignLearnersPayload['learnerSeats'];
}

interface LearnerDataContextShape {
    learnerOrderInfo: LearnerOrderInfoType | undefined;
    learnerSettings: LearnerSettings;
    learnerOrderInfoLoading: boolean;
    handleTemplateGeneration: () => void;
    openAssigningModal: (lineItemId: string) => void;
    batchAssignLearners: (lineItemId: string, learnerSeats: AssignLearnersPayload['learnerSeats']) => Promise<BatchAssignLearnersResponse>;
}

const LearnerDataContext = createContext<LearnerDataContextShape>({} as LearnerDataContextShape);

const LearnerDataProvider: FC<LearnerDataProviderProps> = ({ learnerSettings, learnerHashInfo, children }) => {
    const [openLearnersFields, setOpenLearnersFields] = useState<Fields>(initialOpenLearnersFields);
    const [selectedLineItemId, setSelectedLineItemId] = useState<string>('');
    const [isAssigningModalOpen, setIsAssigningModalOpen] = useState<boolean>(false);
    const [learnerOrderInfo, setLearnerOrderInfo] = useState<LearnerOrderInfoType | undefined>(
        !learnerHashInfo.learnerOrderInfo?.isError ? learnerHashInfo.learnerOrderInfo?.data : undefined
    );
    const [learnerOrderInfoLoading, setLearnerOrderInfoLoading] = useState<boolean>(false);

    const isOrderNumberConfirmationModalOpen = useMemo(
        () => !learnerOrderInfo && learnerHashInfo.status === LearnerHashStatusEnum.OK,
        [learnerOrderInfo, learnerHashInfo.status]
    );

    const selectedLineItem = useMemo(
        () => learnerOrderInfo?.order.lineItems.find(lineItem => lineItem.lineItemId === selectedLineItemId),
        [learnerOrderInfo, selectedLineItemId]
    );

    const [progress, setProgress] = useState<number | undefined>(undefined);

    const getLearnerOrderInfo = useCallback(
        async (
            openLearnersFields: Fields
        ): Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }> => {
            setLearnerOrderInfoLoading(true);
            const payload = {
                orderNumber: openLearnersFields.orderNumber.value,
                hash: learnerHashInfo.hash,
                userAgent: learnerHashInfo.userAgent,
                requestUrl: learnerHashInfo.requestUrl,
                requestMethod: learnerHashInfo.requestMethod
            };

            const learnerOrderInfoResult = await sdk.callAction<LearnerResponse<LearnerOrderInfoType>>({
                actionName: 'learner/getLearnerOrderInfo',
                payload
            });
            setLearnerOrderInfoLoading(false);

            if (learnerOrderInfoResult.isError) {
                return {
                    isError: true,
                    errorMessage:
                        'Error fetching learner order info. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(learnerOrderInfoResult.error.message),
                    fieldErrors: {} as FieldErrors
                };
            }

            if (learnerOrderInfoResult.data.isError) {
                return { isError: true, errorMessage: learnerOrderInfoResult.data.errors[0].message, fieldErrors: {} as FieldErrors };
            }

            setLearnerOrderInfo(learnerOrderInfoResult.data.data);
            return { isError: false };
        },
        [learnerHashInfo, setLearnerOrderInfo, setLearnerOrderInfoLoading]
    );

    const assignLearners = useCallback(
        async (
            lineItemId: string,
            learnerSeats: AssignLearnersPayload['learnerSeats'],
            batchDebug: string
        ): Promise<AssignLearnersResponse> => {
            const payload = {
                hash: learnerHashInfo.hash,
                lineItemId,
                unlockId: learnerOrderInfo?.unlockId,
                learnerSeats,
                userAgent: learnerHashInfo.userAgent,
                requestUrl: learnerHashInfo.requestUrl,
                requestMethod: learnerHashInfo.requestMethod,
                batchDebug
            };

            const assignLearnersResults = await sdk.callAction<AssignLearnersBFFResponse>({
                actionName: 'learner/assignLearners',
                payload
            });

            if (assignLearnersResults.isError) {
                return {
                    isError: true,
                    errorMessage:
                        'Error assigning learners. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(assignLearnersResults.error.message),
                    fieldErrors: {} as Record<string, FieldErrors>
                };
            }

            if (assignLearnersResults.data.error) {
                return {
                    isError: true,
                    errorMessage: assignLearnersResults.data.error.message,
                    fieldErrors: assignLearnersResults.data.error.fieldErrors ?? ({} as Record<string, FieldErrors>)
                };
            }

            const typedResult = assignLearnersResults.data.data!;

            setLearnerOrderInfo(prev => {
                return {
                    ...prev!,
                    order: {
                        ...prev!.order,
                        lineItems: prev!.order.lineItems.map(item => {
                            if (item.lineItemId === lineItemId) {
                                const learnerSeats = item.learnerSeats.concat(typedResult.learnerSeatsAssigned ?? []);
                                return {
                                    ...item,
                                    seats: typedResult.lineItem.seats,
                                    learnerSeats
                                };
                            }
                            return item;
                        })
                    }
                };
            });

            return { isError: false, errorMessage: '', fieldErrors: {} };
        },
        [learnerHashInfo, learnerOrderInfo?.unlockId, setLearnerOrderInfo]
    );

    // assignLearners batching

    const batchAssignLearners = useCallback(
        async (lineItemId: string, learnerSeats: AssignLearnersPayload['learnerSeats']): Promise<BatchAssignLearnersResponse> => {
            const batchSize = learnerSettings.learnersBatchSize;
            const totalBatches = Math.ceil(learnerSeats.length / batchSize);

            if (totalBatches === 1) {
                const assignResult = await assignLearners(lineItemId, learnerSeats, '1/1');
                setProgress(undefined);
                return { ...assignResult, erroredSeats: assignResult.isError ? learnerSeats : [] };
            }

            setProgress(0);

            const result = { isError: false, errorMessage: '', fieldErrors: {}, erroredSeats: [] as AssignLearnersPayload['learnerSeats'] };

            let batchTimeoutThreshold = learnerSettings.batchTimeoutThreshold;

            for (let currentBatch = 0; currentBatch < totalBatches; currentBatch++) {
                const startIndex = currentBatch * batchSize;
                const currentBatchSeats = learnerSeats.slice(startIndex, startIndex + batchSize);
                let waitTimeBetweenBatches = learnerSettings.waitTimeBetweenBatches;

                if (startIndex + currentBatchSeats.length >= batchTimeoutThreshold) {
                    batchTimeoutThreshold += batchTimeoutThreshold;
                    waitTimeBetweenBatches = learnerSettings.waitTimeBetweenBatchesOnThreshold;
                }

                const batchDebug = `batch-${currentBatch + 1}/${totalBatches}, ${currentBatchSeats.length} learners, wait ${waitTimeBetweenBatches}ms before send batch`;
                console.info('---> batchDebug', batchDebug);

                await new Promise(resolve => setTimeout(resolve, waitTimeBetweenBatches));

                console.info('---> call assignLearners', batchDebug);

                const batchAssignResult = await assignLearners(lineItemId, currentBatchSeats, batchDebug);

                if (batchAssignResult.isError) {
                    result.isError = true;
                    result.errorMessage = batchAssignResult.errorMessage;

                    console.info('---> Error in currentBatch', batchDebug, batchAssignResult.errorMessage);

                    // change field errors index

                    const batchAssignResultFieldErrors = {} as Record<string, FieldErrors>;

                    for (const key in batchAssignResult.fieldErrors) {
                        const index = parseInt(key.split('-')[1]);
                        batchAssignResultFieldErrors[`seat-${result.erroredSeats.length + index}`] = batchAssignResult.fieldErrors[key];
                    }

                    result.fieldErrors = { ...result.fieldErrors, ...batchAssignResultFieldErrors };
                    result.erroredSeats = [...result.erroredSeats, ...currentBatchSeats];
                }

                let newProgress = Math.ceil(((currentBatch + 1) / totalBatches) * 100);
                if (newProgress > 100) newProgress = 100;
                setProgress(newProgress);
            }

            setProgress(undefined);
            return result;
        },
        [assignLearners, learnerSettings.learnersBatchSize]
    );

    const handleTemplateGeneration = useCallback(() => {
        const csvContent = 'firstName,lastName,email\n';

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');

        link.download = 'learners.csv';
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    }, []);

    const openAssigningModal = useCallback((lineItemId: string) => {
        setSelectedLineItemId(lineItemId);
        setIsAssigningModalOpen(true);
    }, []);

    const value = useMemo(
        () => ({
            learnerOrderInfo,
            learnerSettings,
            learnerOrderInfoLoading,
            handleTemplateGeneration,
            openAssigningModal,
            batchAssignLearners
        }),
        [learnerOrderInfo, learnerSettings, learnerOrderInfoLoading, handleTemplateGeneration, openAssigningModal, batchAssignLearners]
    );

    return (
        <LearnerDataContext.Provider value={value}>
            <FieldsConfirmationModal
                isOpen={isOrderNumberConfirmationModalOpen}
                fields={openLearnersFields}
                formName="openLearnersFields"
                validateOnBlur={false}
                modalTitle=""
                modalDescription={learnerSettings.confirmOrderNumberModalDescription}
                showCloseButton={false}
                showSubmitButton={true}
                submitButtonText="Confirm"
                handleFieldChange={(field, value) => {
                    setOpenLearnersFields({
                        ...openLearnersFields,
                        [field.name]: {
                            ...field,
                            value
                        }
                    });
                }}
                onClose={() => {}}
                onSubmit={getLearnerOrderInfo}
                errors={{
                    isError: true,
                    errorMessage: learnerHashInfo.learnerOrderInfo?.isError ? learnerHashInfo.learnerOrderInfo?.errors?.[0].message : '',
                    fieldErrors: {} as FieldErrors
                }}
            />
            {learnerOrderInfo && (
                <>
                    <AssignLearnersModal
                        isOpen={isAssigningModalOpen}
                        close={() => setIsAssigningModalOpen(false)}
                        selectedLineItem={selectedLineItem}
                    />

                    <Modal
                        isOpen={progress !== undefined}
                        onRequestClose={() => setIsAssigningModalOpen(false)}
                        className="relative w-1/2 rounded-md bg-white"
                        style={{ content: { maxWidth: '1400px' } }}
                        preventScroll={true}
                    >
                        <div className="bg-white p-30">
                            <div className="m-auto flex h-200 w-400 flex-wrap items-center justify-center">
                                <ProgressBar progress={progress} loadingText="Saving learners..." />
                            </div>
                        </div>
                    </Modal>
                </>
            )}
            {children}
        </LearnerDataContext.Provider>
    );
};

export default LearnerDataProvider;

export const useLearnerDataContext = () => useContext(LearnerDataContext);
